<template>
<v-list
    :model-value="modelValue"
    :density="isMobile ? 'comfortable' : 'compact'"
    @update:model-value="emit('update:modelValue', $event)"
    :nav="!inMenu"
>
    <v-list-item
        v-if="inMenu"
        :class="extraItemClass"
        color="secondary"
        :value="0"
        to="/account"
        exact
        rounded
    >
        Account Home
    </v-list-item>
    <template
        v-if="accountMenu"
        v-for="(value, key) in accountMenu"
        :key="key"
    >
        <AccountNavGroup
            :group="value"
            :extra-item-class="extraItemClass"
        ></AccountNavGroup>
    </template>
    <template v-else>
        <v-list-item>No Items</v-list-item>
    </template>
</v-list>
</template>

<script lang="ts" setup>
    import AccountNavGroup from '~/components/account/account-nav/AccountNavGroup/AccountNavGroup.vue';
    import { useIsMobile, AccountMenu } from '~/composables';

    const props = withDefaults(
        defineProps<{
            modelValue?: Nilish<string>;
            inMenu?: boolean;
        }>(),
        {
            inMenu: false
        }
    );

    const emit = defineEmits<{
        'update:modelValue': [value: string];
    }>();

    const { isMobile } = useIsMobile();
    const accountMenu = AccountMenu();

    const extraItemClass = computed<string>(() => {
        const cls: string[] = [];
        if (!props.inMenu) {
            cls.push('text-uppercase letter-spacing-5 font-weight-medium');
            cls.push(isMobile.value ? 'font-size-7' : 'font-size-8');
        } else {
            cls.push('font-size-6');
        }

        return cls.join(' ');
    });
</script>

<style lang="scss"></style>
