<template>
    <template
        v-if="!group?.hideInMenu && group.items.length && hasVisibleItems"
    >
        <v-list-subheader
            v-if="!group?.hideTitleInMenu"
        >
            <v-list-item-title
                class="mt-6 mb-2 text-overline font-weight-bold"
            >
                {{ group.title }}
            </v-list-item-title>
        </v-list-subheader>

        <AccountNavItem
            v-for="item in group.items"
            :key="item.id"
            :item="item"
            :extra-item-class="extraItemClass"
        ></AccountNavItem>
    </template>
</template>

<script lang="ts" setup>
    import AccountNavItem from '~/components/account/account-nav/AccountNavItem/AccountNavItem.vue';
    import type { AccountNavGroupItem } from '~/types';

    const props = defineProps<{
        group: AccountNavGroupItem;
        extraItemClass: string;
    }>();

    const hasVisibleItems = computed<boolean>(() => !props.group.items.every((g) => g.hideInMenu));
</script>

<style lang="scss"></style>
